.terms-and-conditions h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.terms-and-conditions h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
  color: #555;
  cursor: pointer;
}

.terms-and-conditions h3 {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #777;
}

.terms-and-conditions ul {
  margin-left: 20px;
  list-style-type: disc;
}

.terms-and-conditions p {
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 0.5rem;
}

.collapsible-section {
  margin-bottom: 1.5rem;
}

/* payment-method.css */

/* Styling the payment__info container */
.payment__info {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.payment__info h5 {
  font-size: 24px;
  color: #1a1a40;
  margin-bottom: 20px;
}

/* Styling the payment form */
.payment__form {
  display: flex;
  flex-direction: column;
}

/* Styling the Stripe CardElement */
.payment__card-element {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Focus and invalid states for CardElement */
.payment__card-element.StripeElement--focus {
  border-color: #1a1a40;
}

.payment__card-element.StripeElement--invalid {
  border-color: #e32129;
}

/* Styling the submit button */
.payment__button {
  padding: 12px 20px;
  border-radius: 5px;
  background: #1a1a40;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.payment__button:hover {
  background: #333366;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .payment__info {
    padding: 20px;
  }

  .payment__info h5 {
    font-size: 20px;
  }

  .payment__button {
    padding: 10px 16px;
  }
}

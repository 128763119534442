.about__section-item {
  column-gap: 3rem;
}

.about__section-item p i {
  color: #e32129;
  font-size: 1.1rem;
}

@media only screen and (max-width: 992px) {
  .about__section-content h2 {
    font-size: 1.5rem;
  }
  .about__section-item {
    column-gap: 3rem;
  }
  .about__section-item p {
    font-size: 0.6rem;
    margin-bottom: 0;
  }

  .section__description i {
    font-size: 0.8rem !important;
  }
}
